body {
  margin: 0;

    font-family: graphik;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: graphik;
  src: url(./assets/fonts/graphik_medium.woff);
  font-weight: bold;
}

@font-face {
  font-family: graphik;
  src: url(./assets/fonts/graphik_regular.woff);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

table{
  box-shadow: 0.5px 0.5px 4px 0.5px #BABABA;
}

th, td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-weight: 800;
}

th {
  font-weight: 800;
}

td {
  font-weight: 600;
  font-size: 1.1em;
}

tr:nth-child(even) {background-color: #f2f2f2;}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
 -webkit-user-select: none; /* Safari */
 -khtml-user-select: none; /* Konqueror HTML */
 -moz-user-select: none; /* Firefox */
 -ms-user-select: none; /* Internet Explorer/Edge */
 margin-left: -5%;
}